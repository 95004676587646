// ** Handle User Login
export const handleLogin = data => {
  return dispatch => {
    dispatch({
      type: 'LOGIN',
      token: data.token
    })

    // ** Add to user, accessToken & refreshToken to localStorage
      localStorage.setItem('token', data.token)
  }
}

// ** Handle User Logout
export const handleLogout = () => {
  return dispatch => {
    dispatch({type: 'LOGOUT'})
    // ** Remove user, accessToken & refreshToken from localStorage
    localStorage.removeItem('token')
  }
}
