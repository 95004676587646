import {toast} from "react-toastify"
//import {useSkin} from '@hooks/useSkin'
// layout ||  top-left , top-right , top-center , bottom-left , bottom-right , bottom-center
// types  ||  success , info , warning , error , default
// theme  ||  light , dark , colored


const sendNoty = (type, layout, text, timeout = 5000, theme = 'light',
                  options = {
                      theme: theme,
                      position: layout,
                      autoClose: timeout,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined
                  }) => {
    switch (type) {
        case "success":
            toast.success(text, options);
            break;
        case "info":
            toast.info(text, options);
            break;
        case "warning":
            toast.warn(text, options);
            break;
        case "error":
            toast.error(text, options);
            break;
        case "default":
            toast(text, options);
            break;
        default:
            toast('Set type correctly', options);
            break;
    }
}

export default sendNoty
