// ** Redux Imports
import {combineReducers} from 'redux'

// ** Reducers Imports
import auth from './auth'
import layout from './layout'
import UI from './UI'

const appReducer = combineReducers({
    auth,
    layout,
    UI
})

const rootReducer = (state, action) => {

    if (action.type === "LOGOUT") {
        state.auth = undefined;
    }
    return appReducer(state, action);
};


export default rootReducer
