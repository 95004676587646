import React, {useEffect, useState} from 'react';
import {GetRequest} from "../../configs/request";

export const Category = React.createContext()
function ProductCategoryProvider({children}) {
    const [is_loading, setLoading] = useState(true);
    const [URL] = useState(`/general/ui`)
    const [Categories, setCategories] = React.useState(null);


    useEffect(()=>{
        setLoading(false)
        getProductCategory();
    }, [])
    const getProductCategory = (loading = true) => {
        setLoading(true);
        GetRequest(URL).then((response) => {
            if (response) {
                let result = response.data;
                if (result.ok) {
                    if (result.data) {
                        setCategories(result.data.product_categories);
                        setLoading(false)
                    }
                }
            }
        })
    }
    return (
        <Category.Provider value={Categories} >
            {children}
        </Category.Provider>
    );
}

export default ProductCategoryProvider;